var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-form",
    {
      attrs: { title: _vm.currentNaturalResin.formTitle, "max-width": "600" },
      on: { "save-click": _vm.saveNaturalResin, "esc-press": _vm.onEscPressed },
      model: {
        value: _vm.showNaturalResinModal,
        callback: function($$v) {
          _vm.showNaturalResinModal = $$v
        },
        expression: "showNaturalResinModal"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "naturalResinForm",
          attrs: { "lazy-validation": true },
          model: {
            value: _vm.currentNaturalResin.isModelValid,
            callback: function($$v) {
              _vm.$set(_vm.currentNaturalResin, "isModelValid", $$v)
            },
            expression: "currentNaturalResin.isModelValid"
          }
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Name *",
                  required: "",
                  rules: _vm.currentNaturalResin.isRequiredRules
                },
                model: {
                  value: _vm.currentNaturalResin.naturalResin.name,
                  callback: function($$v) {
                    _vm.$set(_vm.currentNaturalResin.naturalResin, "name", $$v)
                  },
                  expression: "currentNaturalResin.naturalResin.name"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: "Resin Code*",
                  required: "",
                  rules: _vm.currentNaturalResin.isRequiredRules
                },
                model: {
                  value: _vm.currentNaturalResin.naturalResin.resinCode,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.currentNaturalResin.naturalResin,
                      "resinCode",
                      $$v
                    )
                  },
                  expression: "currentNaturalResin.naturalResin.resinCode"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }