import { render, staticRenderFns } from "./NaturalResin.vue?vue&type=template&id=6fcde1b6&"
import script from "./NaturalResin.vue?vue&type=script&lang=ts&"
export * from "./NaturalResin.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VCard,VCardTitle,VContainer,VDataTable,VFlex,VIcon,VLayout,VList,VListTile,VListTileTitle,VMenu,VProgressLinear,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Projects\\WlpScheduler\\front-end\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fcde1b6')) {
      api.createRecord('6fcde1b6', component.options)
    } else {
      api.reload('6fcde1b6', component.options)
    }
    module.hot.accept("./NaturalResin.vue?vue&type=template&id=6fcde1b6&", function () {
      api.rerender('6fcde1b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/naturalResin/NaturalResin.vue"
export default component.exports